<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" class="mt-n3">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                Form group in grid
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >One of three cols</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="One of three cols"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >One of three cols</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="One of three cols"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >One of three cols</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="One of three cols"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >One of four cols</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="One of three cols"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >One of four cols</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="One of three cols"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >One of four cols</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="One of three cols"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >One of four cols</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="One of three cols"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="mb-5">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >One of two cols</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="One of three cols"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="mb-5">
                  <label for="" class="label-color font-weight-600 mb-2 d-block"
                    >One of two cols</label
                  >
                  <v-text-field
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="One of three cols"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                Form controls
              </div>
            </div>

            <v-card-text class="card-padding">
              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Email address</label
              >
              <v-text-field
                hide-details
                outlined
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  mb-6
                "
                placeholder="name@example.com"
              >
              </v-text-field>

              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Example select</label
              >
              <v-select
                :items="items"
                value="1"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  select-style
                "
                outlined
              >
              </v-select>

              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Example multiple select</label
              >
              <v-select
                v-model="e6"
                :items="items"
                :menu-props="{ maxHeight: '400' }"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  select-style
                "
                multiple
                outlined
              ></v-select>

              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Textarea</label
              >
              <v-textarea
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                "
                dense
                flat
                filled
                solo
                height="85"
              ></v-textarea>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                HTML5 Inputs
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="2" class="d-flex align-center">
                  <span class="font-weight-600 text-body">Text</span>
                </v-col>

                <v-col cols="10">
                  <v-text-field
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="John Snow"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="d-flex align-center">
                  <span class="font-weight-600 text-body">Search</span>
                </v-col>

                <v-col cols="10">
                  <v-text-field
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="Tell me your secret..."
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="d-flex align-center">
                  <span class="font-weight-600 text-body">Email</span>
                </v-col>

                <v-col cols="10">
                  <v-text-field
                    type="email"
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="argon@example.com"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="d-flex align-center">
                  <span class="font-weight-600 text-body">URL</span>
                </v-col>

                <v-col cols="10">
                  <v-text-field
                    type="url"
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="https://www.creative-tim.com/"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="d-flex align-center">
                  <span class="font-weight-600 text-body">Password</span>
                </v-col>

                <v-col cols="10">
                  <v-text-field
                    type="password"
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="Password"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="d-flex align-center">
                  <span class="font-weight-600 text-body">Number</span>
                </v-col>

                <v-col cols="10">
                  <v-text-field
                    type="number"
                    hide-details
                    outlined
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                    "
                    placeholder="23"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="d-flex align-center">
                  <span class="font-weight-600 text-body">Date</span>
                </v-col>

                <v-col cols="10">
                  <v-menu
                    ref="menuDate"
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :return-value.sync="dateSimple"
                    transition="slide-y-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        placeholder="23"
                        v-model="dateSimple"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">
                          <v-icon size=".875rem">far fa-calendar</v-icon>
                        </template>
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="dateSimple"
                      no-title
                      scrollable
                      color="bg-primary"
                      header-color="bg-primary"
                      class="card-picker-custom"
                    >
                      <v-spacer></v-spacer>

                      <v-btn
                        @click="menuDate = false"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="
                          font-weight-600
                          text-capitalize
                          btn-ls btn-danger
                          py-3
                          px-6
                          rounded-sm
                        "
                        color="#f5365c"
                        >Cancel</v-btn
                      >

                      <v-btn
                        small
                        @click="$refs.menuDate.save(dateSimple)"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="
                          font-weight-600
                          text-capitalize
                          btn-ls btn-primary
                          py-3
                          px-6
                          rounded-sm
                        "
                        color="#0f88f2"
                        >Ok</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2" class="d-flex align-center">
                  <span class="font-weight-600 text-body">Month</span>
                </v-col>

                <v-col cols="10">
                  <v-menu
                    ref="menuMonth"
                    v-model="menuMonth"
                    :close-on-content-click="false"
                    :return-value.sync="dateMonth"
                    transition="slide-y-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                        "
                        placeholder="23"
                        v-model="dateMonth"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">
                          <v-icon size=".875rem">far fa-calendar</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateMonth"
                      type="month"
                      no-title
                      scrollable
                      color="bg-primary"
                      header-color="bg-primary"
                      class="card-picker-custom"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="menuMonth = false"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="
                          font-weight-600
                          text-capitalize
                          btn-ls btn-danger
                          py-3
                          px-6
                          rounded-sm
                        "
                        color="#f5365c"
                        >Cancel</v-btn
                      >

                      <v-btn
                        small
                        @click="$refs.menuMonth.save(dateMonth)"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        class="
                          font-weight-600
                          text-capitalize
                          btn-ls btn-primary
                          py-3
                          px-6
                          rounded-sm
                        "
                        color="#0f88f2"
                        >Ok</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2" class="d-flex align-center">
                  <span class="font-weight-600 text-body">Year</span>
                </v-col>

                <v-col cols="10">
                  <v-menu
                    ref="menuYear"
                    v-model="menuYear"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                        "
                        placeholder="23"
                        v-model="dateYear"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">
                          <v-icon size=".875rem">far fa-calendar</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="dateYear"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="saveYear"
                      color="bg-primary"
                      header-color="bg-primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2" class="d-flex align-center">
                  <span class="font-weight-600 text-body">Time</span>
                </v-col>

                <v-col cols="10">
                  <v-menu
                    ref="menuTime"
                    v-model="menuTime"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                        "
                        placeholder="23"
                        v-model="time"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template slot="append">
                          <v-icon size=".875rem">far fa-clock</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menuTime"
                      v-model="time"
                      full-width
                      @click:minute="$refs.menuTime.save(time)"
                      color="bg-primary"
                      header-color="bg-primary"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2" class="d-flex align-center">
                  <span class="font-weight-600 text-body">Loading</span>
                </v-col>

                <v-col cols="10">
                  <v-text-field
                    color="bg-primary"
                    class="mt-0 py-0"
                    loading
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mt-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                Checkboxes and radios
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col class="py-0">
                  <v-checkbox
                    v-model="checkbox"
                    color="#0f88f2"
                    :ripple="false"
                    class="ma-0 checkbox-custom checkbox-thinner"
                    hide-details
                  >
                    <template v-slot:label>
                      <span class="text-muted text-body-2 ls-0">Unchecked</span>
                    </template>
                  </v-checkbox>

                  <v-checkbox
                    v-model="checkbox1"
                    color="#0f88f2"
                    :ripple="false"
                    class="ma-0 checkbox-custom checkbox-thinner"
                    hide-details
                  >
                    <template v-slot:label>
                      <span class="text-muted text-body-2 ls-0">Checked</span>
                    </template>
                  </v-checkbox>

                  <v-checkbox
                    v-model="checkbox2"
                    color="#0f88f2"
                    :ripple="false"
                    class="ma-0 checkbox-custom checkbox-thinner"
                    hide-details
                    disabled
                  >
                    <template v-slot:label>
                      <span class="text-muted text-body-2 ls-0"
                        >Disabled unchecked</span
                      >
                    </template>
                  </v-checkbox>

                  <v-checkbox
                    v-model="checkbox3"
                    color="#0f88f2"
                    :ripple="false"
                    class="ma-0 checkbox-custom checkbox-thinner"
                    hide-details
                    disabled
                  >
                    <template v-slot:label>
                      <span class="text-muted text-body-2 ls-0"
                        >Disabled checked</span
                      >
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col class="py-0">
                  <v-radio-group v-model="radios">
                    <v-radio
                      color="#0f88f2"
                      :ripple="false"
                      value="unchecked"
                      class="ma-0 checkbox-custom"
                    >
                      <template v-slot:label>
                        <span class="text-muted text-body-2 ls-0"
                          >Unchecked</span
                        >
                      </template>
                    </v-radio>
                    <v-radio
                      color="#0f88f2"
                      :ripple="false"
                      value="checked"
                      class="ma-0 checkbox-custom"
                    >
                      <template v-slot:label>
                        <span class="text-muted text-body-2 ls-0">Checked</span>
                      </template>
                    </v-radio>
                    <v-radio
                      color="#0f88f2"
                      :ripple="false"
                      value="unchecked"
                      class="ma-0 checkbox-custom"
                      disabled
                    >
                      <template v-slot:label>
                        <span class="text-muted text-body-2 ls-0"
                          >Disabled unchecked</span
                        >
                      </template>
                    </v-radio>
                    <v-radio
                      color="#0f88f2"
                      :ripple="false"
                      value="checked"
                      class="ma-0 checkbox-custom"
                      disabled
                    >
                      <template v-slot:label>
                        <span class="text-muted text-body-2 ls-0"
                          >Disabled checked</span
                        >
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">Sizes</div>
            </div>

            <v-card-text class="card-padding">
              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Large input</label
              >
              <v-text-field
                hide-details
                outlined
                class="
                  input-style
                  font-size-input-lg
                  text-light-input
                  placeholder-light placeholder-light-lg
                  mb-6
                "
                placeholder="input large"
                height="61"
              >
              </v-text-field>

              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Default input</label
              >
              <v-text-field
                hide-details
                outlined
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  mb-6
                "
                placeholder="Default input"
              >
              </v-text-field>

              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Small input</label
              >
              <v-text-field
                hide-details
                outlined
                class="
                  input-style input-height-sm
                  text-light-input
                  placeholder-light placeholder-light-sm
                  font-size-input-sm
                "
                placeholder="input small"
              >
              </v-text-field>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                Text inputs
              </div>
            </div>

            <v-card-text class="card-padding">
              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Basic textarea</label
              >
              <v-textarea
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  mb-6
                "
                dense
                flat
                filled
                solo
                height="85"
              ></v-textarea>

              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Unresizable textarea</label
              >
              <v-textarea
                hide-details
                no-resize
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                "
                dense
                flat
                filled
                solo
                height="85"
              ></v-textarea>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">Select</div>
            </div>

            <v-card-text class="card-padding">
              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Basic select</label
              >
              <v-select
                :items="items"
                value="1"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  select-style
                "
                outlined
              >
              </v-select>

              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Basic select disabled</label
              >
              <v-select
                disabled
                :items="items"
                value="1"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  select-style
                "
                outlined
              >
              </v-select>

              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Multiple</label
              >
              <v-select
                v-model="e6"
                :items="items"
                :menu-props="{ maxHeight: '400' }"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  select-style
                "
                multiple
                outlined
              ></v-select>

              <label for="" class="label-color font-weight-600 mb-2 d-block"
                >Disable Multiple</label
              >
              <v-select
                v-model="e6"
                :items="items"
                :menu-props="{ maxHeight: '400' }"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  select-style
                "
                multiple
                disabled
                outlined
              ></v-select>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                File browser
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-file-input
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                "
                hide-details
                outlined
                clearable
                prepend-icon=""
                placeholder="File input"
              >
                <template slot="append">
                  <v-icon size=".875rem">fas fa-file-upload</v-icon>
                </template>
              </v-file-input>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HeaderTopDashboard from "../../components/HeaderTopDashboard.vue";
export default {
  name: "elements",
  components: {
    HeaderTopDashboard,
  },
  data() {
    return {
      radios: "checked",
      checkbox: false,
      checkbox1: true,
      checkbox2: false,
      checkbox3: true,
      e6: [],
      time: null,
      menuTime: false,
      menuDate: false,
      menuMonth: false,
      menuYear: false,
      dateSimple: new Date().toISOString().substr(0, 10),
      dateMonth: new Date().toISOString().substr(0, 7),
      dateYear: new Date().toISOString().substr(0, 10),
      items: ["1", "2", "3", "4", "5"],
    };
  },
  watch: {
    menuYear(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    saveYear(date) {
      this.$refs.menuYear.save(date);
    },
  },
};
</script>
